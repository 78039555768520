<template>
  <el-card shadow="always" v-loading="isLoading">
    <template #header>
      <el-row type="flex" justify="space-between" align="center">
        <h4>{{ $route.name }}</h4>
        <el-button
          type="primary"
          icon="el-icon-plus"
          size="medium"
          @click="openBilletModal(null)"
        ></el-button>
      </el-row>
    </template>
    <el-table
      stripe
      :cell-style="() => 'text-align:center;'"
      :data="Invoices"
      style="width: 100%; z-index: 0"
    >
      <el-table-column prop="firm.name" label="empresa"></el-table-column>
      <el-table-column
        :formatter="(r) => r.partner?.legal?.social_name || r.partner?.name"
        label="cliente"
      ></el-table-column>
      <el-table-column prop="order.code" label="pedido"></el-table-column>
      <el-table-column
        :formatter="
          (r) => r.created_at && dateFormatter.format(new Date(r.created_at))
        "
        label="data emissão"
      ></el-table-column>
      <el-table-column label="estado" prop="status"> </el-table-column>
      <el-table-column label="ações">
        <template #default="{ row: invoice }">
          <el-row type="flex" justify="end">
            <el-button-group>
              <el-button
                onlyIcon
                type="primary"
                title="Download do PDF"
                :disabled="!invoice?.chave"
                circle
                @click="
                  openInNewTab(
                    `https://nfe.webmaniabr.com/danfe/${invoice?.chave}`
                  )
                "
              >
                <i class="el-icon-download"></i
              ></el-button>
              <el-popconfirm
                @confirm="removeInvoice(invoice)"
                title="
              Tem certeza?
            "
                ><template #reference>
                  <el-button onlyIcon type="danger" circle>
                    <i class="el-icon-delete"></i
                  ></el-button> </template
              ></el-popconfirm>
            </el-button-group>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @update:current-page="currentPage = $event"
      :current-page="currentPage"
      background
      layout="prev, pager, next"
      :page-count="invoices?.lastPage"
    >
    </el-pagination>
  </el-card>
</template>
<script>
import InvoiceService from "../services/invoices";
import AccountService from "../services/accounts";
import { notifySuccess, notifyError } from "../utils/notifiers";
import { dateFormatter, currencyFormatter } from "../utils/formatters";
import { hasOnlyDigits } from "../utils/functions";
export default {
  name: "Invoices",
  data() {
    return {
      showBilletModal: false,
      billet: null,
      invoices: null,
      isLoading: true,
      dateFormatter,
      currencyFormatter,
      currentPage: 1,
      statusToType: {
        created: "info",
        emitted: "info",
        registered: "info",
        error: "danger",
        paid: "success",
        deleted: "danger",
        expired: "warning",
      },
      statusToLabel: {
        created: "CADASTRADO",
        emitted: "EMITIDO",
        registered: "REGISTRADO",
        error: "PROBLEMA",
        paid: "PAGO",
        deleted: "BAIXADO",
        expired: "VENCIDO",
      },
    };
  },
  computed: {
    Invoices() {
      return this.invoices?.data || [];
    },
    SearchName() {
      return this.$store.state.searchNameQuery;
    },
  },
  mounted() {
    this.fetchInvoices();
  },
  watch: {
    currentPage() {
      this.fetchInvoices();
    },
    SearchName() {
      this.fetchInvoices();
    },
  },
  methods: {
    async removeInvoice(_) {
      const { invoice } = await InvoiceService(_.uid).delete();

      if (invoice) notifySuccess(invoice.message);
      else notifyError("Ocorreu um erro ao solicitar a baixa");

      this.fetchInvoices();
    },
    openInNewTab(url) {
      window.open(url, "_blank");
    },
    openBilletModal(billet) {
      this.billet = billet;
      this.showBilletModal = true;
    },
    async deleteFirmAccount(acc) {
      const { account, error } = await AccountService(acc.uid).delete();

      if (account) notifySuccess(account.message);
      else notifyError(error.message);
    },
    async fetchInvoices() {
      this.isLoading = true;
      const { invoices } = await InvoiceService().index({
        pagination: this.currentPage,
        searchName: hasOnlyDigits(this.SearchName) ? "" : this.SearchName,
        searchNumber: hasOnlyDigits(this.SearchName) ? this.SearchName : "",
        deleted_at: new Date(),
      });

      if (invoices) this.invoices = invoices;

      this.isLoading = false;
    },
  },
};
</script>
